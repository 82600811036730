import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ToasterCard from "./plugins/Toaster";
import AppRoute from "./plugins/router";
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <>
      <CookiesProvider>
        <ToasterCard />
        <AppRoute />
      </CookiesProvider>
    </>
  );
}

export default App;
