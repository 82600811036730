import React from "react";
import { PopupButton } from "react-calendly";

const CalendlyWidget = () => {
  return (
    <PopupButton
      url="https://calendly.com/client_meeting/lozrian-meetings?&hide_gdpr_banner=1&primary_color=9b5de5&embed_type%5B%5D=PopupWidget&embed_type%5B%5D=PopupWidget&embed_domain%5B%5D=1&embed_domain%5B%5D=1&back=1"
      rootElement={document.getElementById("root")}
      primary_color="9b5de5"
      color="9b5de5"
      text="Book A Call"
      className="mx-auto custom-primary btn btn-primary"
      // pageSettings={{ backgroundColor: "#0b1d30", textColor: "#ffffff" }}
    />
  );
};

export default CalendlyWidget;
