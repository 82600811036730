import { routes } from "../../../../configs/configNavLink";

const Routes = [
  {
    header: "Home",
    link: routes.home,
    id: "Home",
    section: true,
  },
  {
    header: "About Us",
    link: routes.about,
    id: "About",
    section: true,
  },
  {
    header: "Services",
    link: routes.Services,
    id: "Services",
    section: true,
  },
  {
    header: "Contact Us",
    link: routes.ContactUs,
    id: "Contact",
    section: true,
  },
  // {
  //   header: "Marketplace",
  //   link: routes.Marketplace,
  //   id: "Marketplace",
  // },
];
export default Routes;
