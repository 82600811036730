import * as yup from "yup";
/*  use Yup schema as builder for value parsing and validation
 * ref :  https://www.npmjs.com/package/yup
 * khaled Mofeed */
export default yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  message: yup.string().min(20).required(),
  "g-recaptcha-response": yup
    .string()
    .nullable(true)
    .required("You must check the reCAPTCHA."),
});
