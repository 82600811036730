import React from "react";
import { routes } from "../configs/configNavLink";
import Layout from "../pages/Layout";
import Home from "../pages/home";
import NotFound from "../pages/NotFound";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    component: <Layout />,
    attributes: {
      path: "/",
      id: "Layout",
    },
    children: [
      {
        header: "Home",
        component: <Home />,
        attributes: {
          path: routes.home,
          id: "Home",
        },
      },
    ],
  },

  {
    component: <NotFound />,
    attributes: {
      path: "*",
      id: "notFound",
    },
  },
];
