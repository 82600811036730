import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Animation from "./useAnimation";

function WhyChooseLozrian(props) {
  /*  this is hero section component used in token page  khaled Mofeed | 29/10/2022  */
  return (
    <Row className="justify-content-center" id="about">
      <Col md={10}>
        <Row className="align-items-center minHeight-85 mt-n-5">
          <Col lg={6} className="WhyChooseLozrian-content">
            <h1 id="id_h1WhyChooseLozrian">
              Why Choose <br />
              Lozrian?
            </h1>
            <p id="id_Lozrian_p1">
              We're committed to providing luxury quality games, using
              cutting-edge technology such as AR, VR, Unity development, and
              Web3 integration. We offer customized solutions tailored to our
              clients' specific needs and target audience, and our world-class
              support ensures that your gaming experience remains top-notch.
            </p>
          </Col>
          <Col lg={6} className="WhyChooseLozrianContentImg d-lg-block d-none">
            <Animation />
          </Col>
          <Col lg={6} className="WhyChooseLozrianContentImg d-block d-lg-none">
            <img
              src="/img/WhyChooseLozrian.svg"
              alt="WhyChooseLozrian"
              className="WhyChooseLozrian "
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default WhyChooseLozrian;
