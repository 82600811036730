import React, { useState } from "react";
import schema from "./schema";
import toast from "react-hot-toast";

import "./style.css";
import { Button } from "react-bootstrap";
import { request } from "../../../../plugins/axios";
import { endpoints } from "../../../../utils/networks";
import { Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

function Form({ HideBookCallModal, categoryId, ...props }) {
  function onChange(value) {
    setFormdata({
      ...formdata,
      "g-recaptcha-response": value,
    });
  }
  const [loading, setLoading] = useState(false);

  /*  use the useState custom hook to define variables | khaled Mofeed  */
  const [formdata, setFormdata] = useState({
    category_id: 1,
    name: "",
    email: "",
    message: "",
    "g-recaptcha-response": "",
  });
  const [error, setError] = useState(false);
  const recaptchaRef = React.createRef();
  const grecaptchaObject = window.grecaptcha; // You must provide access to the google grecaptcha object.

  /* Define an empty variable to be used to dump the form after submitting | khaled Mofeed  */
  const initialData = {
    category_id: 1,
    name: "",
    email: "",
    message: "",
    "g-recaptcha-response": "",
  };

  const submitGetInTouch = (event) => {
    request(
      endpoints.MetaChainApi.SendRequest.method,
      endpoints.MetaChainApi.SendRequest.url,
      formdata
    )
      .then(({ data }) => {
        setLoading(true);
        toast.success(`You message has been sent !`);
        setFormdata(initialData);
        window.grecaptcha.reset();
        HideBookCallModal();
      })
      .catch(({ response }) => {
        setLoading(false);

        if (response.data.errors) {
          Object.keys(response.data.errors).forEach((_error) => {
            toast.error(response.data.errors[_error][0]);
          });
        }
      });
  };

  /*  this function is used to submit Form | khaled Mofeed */
  const submitHandler = (event) => {
    event.preventDefault();
    /*  this schema is used to validator fields which contains the error messages | khaled Mofeed   */
    schema
      .validate(formdata, { abortEarly: false })
      .then(() => {
        submitGetInTouch(formdata);
      })
      .catch((error) => {
        setError(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /*  this function used to handle value of input to set it to state | khaled Mofeed  */
  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setError({
      ...error,
      [event.currentTarget.name]: false,
    });
  };

  return (
    <>
      <form className="form row position-relative" onSubmit={submitHandler}>
        <div className="form-group col-12 col-md-6 mb-3">
          <input
            type="text"
            placeholder="Name"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.name && "is-invalid"
            }`}
            name="name"
            // placeholder="Full Name"
            onChange={handleChange}
            value={formdata.name}
            id="id_nameGetTouch"
          />
          {error.name && (
            <div className="invalid-feedback" id="id_nameErrorGetTouch">
              {error.name}
            </div>
          )}
        </div>
        <div className="form-group col-12 col-md-6 mb-3">
          <input
            type="email"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.email && "is-invalid"
            }`}
            name="email"
            placeholder="Email address"
            onChange={handleChange}
            value={formdata.email}
          />
          {error.email && <div className="invalid-feedback">{error.email}</div>}
        </div>
        <div className="form-group col-12 mb-3">
          <textarea
            name="message"
            placeholder="Message"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.message && "is-invalid"
            }`}
            rows="6"
            // placeholder="Message"
            onChange={handleChange}
            value={formdata.message}
            id="id_messageGetTouch"
          ></textarea>
          {error.message && (
            <div className="invalid-feedback" id="id_msgErrorGetTouch">
              {error.message}
            </div>
          )}
        </div>
        <div className="form-group col-12 mb-3 position-relative">
          <ReCAPTCHA
            size="normal"
            badge="inline"
            ref={recaptchaRef}
            sitekey="6Lc3MEUlAAAAAAoA9IRSIteAONK4bFvi5qCUCTHo"
            onChange={onChange}
            grecaptcha={grecaptchaObject}
            className={`${error["g-recaptcha-response"] && "is-invalid"}`}
          />
          {error["g-recaptcha-response"] && (
            <div className="invalid-feedback">
              {error["g-recaptcha-response"]}
            </div>
          )}
          <img
            src="/img/Paper-.png"
            alt="send"
            className="sendIcon d-none d-lg-block"
          />
        </div>
        <div className="col-12 col-md-12">
          <Button
            variant="secondary"
            className="custom-primary px-10 w-100"
            id="id_DiscoverBtn"
            disabled={loading}
            type="submit"
          >
            Send now
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export default Form;
