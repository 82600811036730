import { HTTP_GET_METHOD, HTTP_POST_METHOD } from "../constants";

export const endpoints = {
  MetaChainApi: {
    SendRequest: {
      method: HTTP_POST_METHOD,
      url: "/send-request",
    },
    GetCategories: {
      method: HTTP_GET_METHOD,
      url: "/get-categories",
    },
  },
};
