import React, { useState } from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiBars3 } from "react-icons/hi2";
import RouteGenerator from "../Header/routes/RouteGenerator";
import CookieConsent from "../../../components/CookieConsent";
/*  This Component for displaying Footer 
*  Defining the data of social media from the config social media  file
 | khaled Mofeed */

function Footer() {
  return (
    <Row className="justify-content-center footer">
      <Col md={12} lg={10}>
        <Navbar expand="lg" sticky="top">
          <Navbar.Brand>
            <Link to="/">
              <img
                id="id_logo"
                src="/img/logoFooter.svg"
                alt="logo"
                className="logo d-none d-lg-block"
              />
              <img
                id="id_logoMobile"
                src="/img/logoFooter.svg"
                alt="logo"
                className="logo-mobile d-block d-lg-none"
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" id="id_ToggleBtn">
            <HiBars3 />
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {/*  This element for  static menu in header | khaled Mofeed*/}
              <RouteGenerator />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
      <Col md={12} lg={10}>
        <Row>
          <Col md={12} lg={7}>
            <Nav className="CopyRightFooter">
              <span className="copyRight" key="LinkFooter1">
                Copyright © 2023 Lozrian L.L.C-FZ. All rights reserved
              </span>
              <Link className="nav-link" to="#" key="LinkFooter2">
                Privacy Policy
              </Link>
              <Link className="nav-link" to="#" key="LinkFooter2">
                Team of Use
              </Link>
            </Nav>
          </Col>
          <Col md={12} lg={5}>
            <CookieConsent />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
