import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
function Services(props) {
  /*  this is hero section component used in token page  khaled Mofeed | 29/10/2022  */
  return (
    <>
      <img
        src="/img/Services.svg"
        alt="Services"
        className="ServicesImage pb-5"
        id="services"
      />
      <Row className="justify-content-center">
        <Col md={10}>
          <Row className="align-items-center minHeight-85">
            <Col lg={12} className="Services-content">
              <h1 id="id_h1Services">Our Services</h1>
            </Col>
            <Col lg={12} className="Services-content">
              <Row className="align-items-center justify-content-center ">
                <Col lg={4}>
                  <div className="OurServices-content">
                    <img
                      src="/img/MobileGameDevelopment.svg"
                      alt="MobileGameDevelopment"
                      className="OurServicesImage"
                    />
                    <h5 id="id_h5Services">Mobile Game Development</h5>
                    <p>
                      Craft exceptional mobile games with stunning graphics,
                      engaging gameplay, and seamless performance.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="OurServices-content">
                    <img
                      src="/img/VirtualReality.svg"
                      alt="VirtualReality"
                      className="OurServicesImage"
                    />
                    <h5 id="id_h5Services">Virtual Reality (VR) Development</h5>
                    <p>
                      Create unforgettable virtual worlds that transport players
                      into truly immersive environments.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="OurServices-content">
                    <img
                      src="/img/AugmentedReality.svg"
                      alt="AugmentedReality"
                      className="OurServicesImage"
                    />
                    <h5 id="id_h5Services">
                      Augmented Reality (AR) Development
                    </h5>
                    <p>
                      Enhance the real world with interactive and immersive AR
                      experiences for gaming, education, and more.{" "}
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="OurServices-content">
                    <img
                      src="/img/UnityDevelopment.svg"
                      alt="UnityDevelopment"
                      className="OurServicesImage"
                    />
                    <h5 id="id_h5Services">Unity Development</h5>
                    <p>
                      Build versatile, cross-platform gaming experiences with
                      the power and flexibility of Unity.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="OurServices-content">
                    <img
                      src="/img/Web3GameServices.svg"
                      alt="Web3GameServices"
                      className="OurServicesImage"
                    />
                    <h5 id="id_h5Services">Web3 In-Game Services</h5>
                    <p>
                      Integrate next-generation Web3 technology, such as
                      blockchain, NFTs, and decentralized finance, to provide
                      players with novel, secure, and immersive gaming
                      experiences.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Services;
