import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppRouters from "../../routes";

const AppRoute = () => {
  return (
    <BrowserRouter>
      {/* <AppContextProvider theme={DARK_THEME}> */}
      <Routes>
        {AppRouters?.map((layout) => {
          return (
            <Route
              element={layout.component}
              {...(layout.attributes ?? {})}
              key={"layout-" + layout.path}
            >
              {layout?.children?.map((page) => (
                <Route
                  element={page.component}
                  {...(page.attributes ?? {})}
                  key={"page" + page.path}
                />
              ))}
            </Route>
          );
        })}
      </Routes>
      {/* </AppContextProvider> */}
    </BrowserRouter>
  );
};

export default AppRoute;
