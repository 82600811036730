import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "./Form";
function GetTouch(props) {
  /** import the Form Component  | khaled Mofeed */
  return (
    <Row
      className="justify-content-center align-items-center min-height-100per Team GetTouch pt-5"
      id="ContactUs"
    >
      <Col md={10} xl={10} className="pt-5">
        <Row className="align-items-center justify-content-center  GetInTouchTitle">
          <Col lg={10}>
            <h2>Get in touch</h2>
            <p>
              Ready to elevate your gaming experience to new heights? Contact
              Lozrian L.L.C-FZ today and let us bring your vision to life. Visit
              us at our office located in Business Center 1, M Floor, The Meydan
              Hotel, Nad Al Sheba, Dubai, U.A.E, or reach out to us through our
              contact form.
            </p>
          </Col>
        </Row>
        <Row className="align-items-center lozrian-world">
          <Col lg={5}>
            <Row className="justify-content-center align-items-center bg-border-gradiant contact-form-bg">
              <Col lg={12} xl={12}>
                <Form />
              </Col>
            </Row>
          </Col>

          <Col className="lozrian-world-text" lg={7}>
            <div className="rightImgMsg position-relative d-none d-lg-block">
              {/* <img
                src="/img/global1.png"
                alt="global1"
                className="big-circle"
              />
              <img
                src="/img/global2.png"
                alt="global2"
                className="small-circle"
              />
              <img
                src="/img/global-dot1.png"
                alt="global-dot1"
                className="dot"
              />
              <img
                src="/img/global-dot2.png"
                alt="global-dot2"
                className="dot2"
              /> */}
              {/* <img src="/img/map.png" alt="map" className="global" /> */}
              {/* <img
                src="/img/circleBG.svg"
                alt="circleBG"
                fluid
                className="circleBG"
              /> */}
              <img
                src="/img/Expertise/Shape2.png"
                alt="global2"
                className="small-circle"
              />
              <img
                src="/img/message.svg"
                alt="message"
                fluid
                className="messageImg"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GetTouch;
