import React, { useEffect, useRef } from "react";

function Animation() {
  const leftItemRef = useRef(null);

  useEffect(() => {
    const leftItem = leftItemRef.current;

    const throttle = function (type, name, obj) {
      var obj = obj || window;
      var running = false;
      var func = function () {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function () {
          obj.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      obj.addEventListener(type, func);
    };

    throttle("scroll", "optimizedScroll");

    function handleOptimizedScroll() {
      if (leftItem) {
        leftItem.style.transform = `scale(${
          (window.pageYOffset + 500) / 1800
        })`;
      }
    }

    window.addEventListener("optimizedScroll", handleOptimizedScroll);

    return () => {
      window.removeEventListener("optimizedScroll", handleOptimizedScroll);
    };
  }, []);

  return (
    <div id="item0" ref={leftItemRef}>
      <img
        src="/img/WhyChooseLozrian.svg"
        alt="WhyChooseLozrian"
        className="WhyChooseLozrian"
      />
    </div>
  );
}

export default Animation;
