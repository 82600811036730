const TOKEN = localStorage.getItem("token");
export const config = {
  // api's base url
  baseURL: "https://back.lozrian.com/api",
  // requeired headers
  headers: {
    "Content-Type": "application/json",
    Authorization:
      TOKEN && TOKEN.includes("Bearer") ? TOKEN : `Bearer ${TOKEN}`,
  },
};
