import React from "react";
import { useCookies } from "react-cookie";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  const handleAccept = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  if (cookies.cookieConsent) {
    return (
      <p className="cookies">Thank you for accepting the use of cookies</p>
    );
  }

  return (
    <p className="cookies">
      We use cookies for better service.{" "}
      <span onClick={handleAccept}>Accept</span>
    </p>
  );
};

export default CookieConsent;
