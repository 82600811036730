import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
function Expertise(props) {
  /*  this is hero section component used in token page  khaled Mofeed | 29/10/2022  */
  return (
    <Row className="justify-content-center">
      <Col md={10}>
        <Row className="align-items-center minHeight-85">
          <Col lg={12} className="Expertise-content">
            {/* <img src="/img/global1.png" alt="global1" className="big-circle" /> */}
            <img
              src="/img/Expertise/Shape.png"
              alt="global1"
              className="big-circle"
            />
            <img
              src="/img/Expertise/Shape2.png"
              alt="global1"
              className="big-circle2"
            />
            <h1 id="id_h1Expertise">Our Expertise</h1>

            <p id="id_Lozrian_p">
              At Lozrian, we specialize in the development of high-quality
              mobile games, augmented reality (AR), virtual reality (VR),
              Unity-based projects, and Web3 in-game services. Our team of
              seasoned developers and creative designers bring their passion for
              gaming and years of experience to deliver truly immersive and
              visually stunning experiences for our clients.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Expertise;
