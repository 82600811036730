import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
function Herosection(props) {
  /*  this is hero section component used in token page  khaled Mofeed | 29/10/2022  */
  return (
    <Row className="justify-content-center" id="home">
      <Col md={10}>
        <Row className="align-items-center min-height-100">
          <Col lg={5} className="hero-content">
            <h1 id="id_h1Telv">
              Welcome To <br />
              Lozrian Luxury <br />
              Gaming,
              <br /> Redefined
            </h1>

            <p id="id_Lozrian_p">
              Lozrian L.L.C-FZ is a premier AR, VR, and mobile game development
              company based in the heart of Dubai, United Arab Emirates. Our
              mission is to create unforgettable gaming experiences by merging
              cutting-edge technology, including Web3, with unparalleled luxury
              and innovation.
            </p>
          </Col>
          <Col lg={7} className="position-relative overflow-hidden">
            <div className="WelcomToLozrian d-none">
              {/* <img
                src="/img/HeroImages/Background.png"
                className="welcome1"
                alt="Background"
                id="id_Background"
              />
              <img
                src="/img/HeroImages/Mobile.png"
                className="welcome2"
                alt="Mobile"
                id="id_Mobile"
              />
              <img
                src="/img/HeroImages/PinkStar.png"
                className="welcome3"
                alt="PinkStar"
                id="id_PinkStar"
              />
              <img
                src="/img/HeroImages/Green_.png"
                className="welcome4"
                alt="Green_"
                id="id_Green_"
              />
              */}
            </div>
            <img
              src="/img/HeroImages/Mobile.png"
              className="welcome"
              alt="welcome"
              id="id_welcome"
            />
            <img
              src="/img/HeroImages/Hand.png"
              className="welcome6"
              alt="Hand"
              id="id_Hand"
            />
            <img
              src="/img/HeroImages/Trophy.png"
              className="welcome5"
              alt="Trophy"
              id="id_Trophy"
            />
            <img
              src="/img/HeroImages/Green_.png"
              className="welcome4"
              alt="Green_"
              id="id_Green_"
            />
            <img
              src="/img/HeroImages/PinkStar.png"
              className="welcome3"
              alt="PinkStar"
              id="id_PinkStar"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Herosection;
