import React from "react";
import { Link, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Dropdown } from "react-bootstrap";
import Routes from "./Routes";
import { useLocation } from "react-router-dom";
import Location from "./Location";
function RouteGenerator() {
  const location = useLocation();
  // const handleMenuClick = (event, route) => {
  //   event.preventDefault();
  //   const target = document.querySelector(route);
  //   target.scrollIntoView({ behavior: "smooth", block: "start" });
  // };
  return (
    <>
      {Routes.map((route) => {
        return route.children ? (
          <Dropdown className="top-menue">
            <Dropdown.Toggle
              variant="outline-secondary"
              className="top-menue-title"
            >
              {route.header}
            </Dropdown.Toggle>

            <Dropdown.Menu className="DropdownMenu">
              {route.children.map(({ id, link, header }) => (
                <Nav key={id}>
                  <NavLink to={link} className="nav-link" key={id} end>
                    {header}
                  </NavLink>
                </Nav>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <>
            <Nav key={route.link} id={route.id}>
              <a href={route.link} key={route.header} className="nav-link">
                {route.header}
              </a>
              {/* {route.section ? (
                <Location route={route} />
              ) : (
                <Link
                  className="nav-link"
                  to={route.link}
                  // end
                  key={route.header}
                >
                  {route.header}
                </Link>
              )} */}
            </Nav>
          </>
        );
      })}
    </>
  );
}
export default RouteGenerator;
