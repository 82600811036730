import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
function index(props) {
  return (
    <Row className="justify-content-center align-items-center  pageNotFoundMin-height-100per">
      <Col md={5} xl={5} className="text-center">
        <div className="text-center w-100">
          <img
            src="/img/PageNotFound.png"
            alt="PageNotFound"
            className="PageNotFound w-100"
            id="id_PageNotFound"
          />
        </div>
        <h1 id="id_titlePageNotFound" className="titlePageNotFound text-center">
          PAGE NOT FOUND
        </h1>
        <Link
          to="/"
          variant="secondary"
          className="custom-primary btn btn-primary px-5 py-3"
          id="id_PageNotFoundHome"
        >
          GO HOME
        </Link>
      </Col>
    </Row>
  );
}

export default index;
