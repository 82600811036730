import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Expertise from "./components/Expertise";
import GetTouch from "./components/GetTouch";
import Herosection from "./components/HeroSection";
import Services from "./components/Services";
import WhyChooseLozrian from "./components/WhyChooseLozrian";

function Index(props) {
  return (
    <>
      <Container fluid>
        <Header />
        <Herosection />
        <Expertise />
        <WhyChooseLozrian />
        <Services />
        <GetTouch />
      </Container>

      <Container fluid>
        <Footer />
      </Container>
    </>
  );
}

export default Index;
